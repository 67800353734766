.ContactTitle {
  font-family: 'Algerian', sans-serif;
  font-size: 4rem; /* Équivaut à 54px si 1rem = 16px */
  color: #ffc107;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.ContactContent {
  color: #fff;
  font-size: 1.75rem; /* 28px / 16px = 1.75rem */
  font-family: Baskerville, serif;
}

.GetDirectionsButton {
  padding: 12px 24px;
  background-color: #ffc107; /* Changement de la couleur de fond pour correspondre au titre */
  color: #000; /* Changement de la couleur du texte pour améliorer le contraste */
  border: 2px solid transparent; /* Ajout d'une bordure pour maintenir la forme lors du hover */
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.75rem;
  font-family: Baskerville, serif; /* Harmonisation avec le style du texte de la page */
  transition: background-color 0.3s, color 0.3s; /* Effet de transition doux */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Ajout d'ombre pour un effet de profondeur */
}

.GetDirectionsButton:hover {
  background-color: #b28900; /* Couleur plus foncée au survol */
  color: white; /* Changement de couleur du texte pour le contraste */
  border: 2px solid #ffc107; /* Bordure dorée pour un effet visuel renforcé */
}


/* Media query pour les petits écrans */
@media (max-width: 390px) {
  .ContactContent {
    font-size: 1.5rem; /* légèrement plus petit pour les petits écrans */
  }

  .ContactTitle {
    font-size: 3rem; /* Ajuster la taille de la police pour les petits écrans */
    top: 4rem; /* Ajuster la position du titre vers le bas */
    left: 50%; /* Centrer horizontalement */
    transform: translate(-50%, 0); /* Assurer le centrage horizontal */
    position: fixed; /* Maintenir la position fixe */
  }

  .GetDirectionsButton {
    font-size: 1.5rem; /* Ajuster la taille de la police du bouton pour les petits écrans */
    padding: 8px 16px; /* Ajuster le padding pour les petits écrans */
  }
}

.Signature {
  position: fixed;
  bottom: 20px;        /* Positionner le footer en bas de la page */
  left: 0;          /* Aligner à gauche de la fenêtre */
  right: 0;         /* Aligner à droite de la fenêtre */
  font-size: 1rem;
  color: grey;
  text-align: center; /* Centrer le texte horizontalement */
  margin-top: 20vh;   /* Ajout d'une marge supérieure si nécessaire */
}
