.AboutUsTitle {
  font-family: 'Algerian', sans-serif;
  font-size: 4rem; /* Équivaut à 54px si 1rem = 16px */
  color: #ffc107;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
  
  .content {
    color: #fff; 
    font-size: 2em; 
    font-family: Baskerville;
    text-align: center;
    justify-content: center;
    margin-top: 120px;
  }  

  @media (max-width: 600px) {
    .AboutUsTitle {
      font-size: 3rem; /* Réduire la taille de la police à 32px */
      position: static; /* Enlever le positionnement fixe pour permettre au contenu de suivre */
      transform: none;
      justify-content: center;
      justify-items: center;
      left: 0;
      top: 1;
      margin-top: 20px; /* Ajouter une marge en haut */
    }
  
    .content {
      font-size: 1.25em; /* Réduire la taille à 20px */
      margin-top: 10px; /* Réduire l'espace au-dessus du contenu */
    }
  }