.menu-hamburger-container {
  position: relative; /* Contexte de positionnement pour le contenu du menu */
}

.hamburger-icon {
  position: fixed; /* ou 'absolute' si vous ne voulez pas qu'elle reste en place lors du défilement */
  top: 1.25rem; /* 20px / 16 */
  left: 1.25rem; /* 20px / 16 */
  z-index: 1000; /* S'assure qu'elle est au-dessus de tout autre contenu */
  cursor: pointer;
  background: none;
  color: #FFCC00;
  border: none;
  font-size: 2.8125rem; /* 45px / 16 */
}

.menu-content {
  position: fixed;
  top: 6.25rem; /* 100px / 16 */
  left: 1.875rem; /* 30px / 16 */
  width: 9.375rem; /* 150px / 16 */
  background: #333;
  color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.2);
  z-index: 999;
  font-size: 1rem; 
  padding: 0.625rem; /* 10px / 16 */
}

/* Styles pour les éléments de liste dans le menu */
.menu-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-content li {
  padding: 0.75rem 1.25rem; /* 12px 20px / 16 */
  cursor: pointer;
}

.menu-content li:hover {
  background-color: #555;
}

.menu-content li:not(:last-child) {
  border-bottom: 1px solid #474747;
}

/* Media queries pour les écrans de tablettes et de téléphones mobiles */
@media (max-width: 768px) {
  .menu-content {
    width: 100%; /* Le menu prend toute la largeur */
    left: 0;
    top: 0; /* Optionnel: le menu pourrait s'étendre du haut de l'écran */
    padding-top: 1rem; /* Ajustez pour l'espacement au-dessus du premier élément */
  }

  .hamburger-icon {
    font-size: 2rem; /* Ajustez si vous souhaitez que l'icône soit plus petite sur les tablettes */
  }
}

@media (max-width: 480px) {
  .hamburger-icon {
    font-size: 2.5rem; /* Ajustez pour les téléphones mobiles */
  }
}
