  
  /* Styles existants pour .HomePageTitle, applicable à toutes les dimensions */
.HomePageTitle {
  font-family: 'Algerian', sans-serif;
  margin-bottom: 6rem;
  font-size: 10rem; /* Taille actuelle, pour les très grands écrans */
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
}

  .full-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
    position: relative;
    color: #cca500;
  }
  
  
  /* Styles existants du menu hamburger */
.menu-hamburger {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1rem; /* Augmenté pour améliorer la visibilité et l'accessibilité */
  cursor: pointer;
  z-index: 100;
}

/* Augmentation de la taille pour les mobiles */
@media (max-width: 80rem) { /* 480px / 16 = 30rem */
  .menu-hamburger {
    font-size: 30rem; /* Augmente la taille de la police pour améliorer la visibilité */
    padding: 1.5rem; /* Augmente le padding pour une meilleure accessibilité tactile */
  }
}
  
  .logo {
    width: 100%;
    max-width: 2000px; /* Ou tout autre max-width approprié */
    height: auto; /* Maintient l'aspect ratio */
    z-index: 10;
    margin: auto; /* Ajoute automatiquement une marge au-dessus et en dessous si nécessaire */
    margin-top: -200px; /* Remonte le logo de 50px; ajustez selon le besoin */

  }
  
  
  /* Pour les écrans de tablettes */
@media (max-width: 100rem) { /* 768px / 16 = 48rem */
  .logo {
    max-width: 60rem; /* 600px / 16 = 37.5rem */
    margin-bottom: 3.125rem; /* 50px / 16 = 3.125rem */
  }
  .HomePageTitle {
    font-size: 6rem; /* 36px / 16 = 2.25rem */
  }
}

  /* Pour les écrans de téléphones mobiles */
  @media (max-width: 120rem) {
    .video-container .logo {
      width: 600vw; /* Ajustez ce pourcentage selon les besoins pour la taille optimale */
      height: auto; /* Garde le ratio d'aspect correct */
    }
  }
  



  .HomePageTitle {
    font-size: 1.5rem; /* 24px / 16 = 1.5rem */
  }



  .video-container {
    width: 100%; /* Assure que le conteneur peut grandir */
    max-width: none; /* Enlève toute restriction de max-width */
  }
  

.mute-icon {
  position: absolute;
  bottom: 400px; /* Rapprochez de la vidéo en bas */
  right: 400px; /* Rapprochez de la vidéo sur le côté droit */
  font-size: 24px; /* Taille de l'icône, ajustez selon vos besoins */
  color: white; /* Choisissez une couleur qui ressort bien sur la vidéo */
  background: rgba(0, 0, 0, 0.5); /* Arrière-plan semi-transparent pour une meilleure visibilité */
  padding: 5px; /* Un peu de padding autour de l'icône */
  border-radius: 50%; /* Style circulaire pour l'icône */
  cursor: pointer;
}


  
  
  
  
