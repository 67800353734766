/* OurMenu.css */

.menuContainer {
  height: 100vh;
  /* autres styles pour .menuContainer */
}

.menuTitle {
  font-family: 'Algerian', sans-serif;
  font-size: 4rem; /* Adaptez cette taille si nécessaire */
  color: #ffc107;
  position: fixed;
  top: 1vh; /* Ajustement pour éloigner le titre du haut de la page */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

/* Style pour l'image sous le titre pour éviter le chevauchement */
.imageStyle {
  margin-top: 15vh; /* Augmentation de la marge pour un meilleur espacement */
  max-width: 80vw;
  max-height: 80vh;
  object-fit: contain; /* Correction de la syntaxe */
}

@media (max-width: 600px) {
  .menuTitle {
    font-size: 2rem; /* Taille du titre réduite sur les petits écrans */
  }
  .imageStyle {
    margin-top: 10vh; /* Augmentation de la marge pour les petits écrans */
  }
}
