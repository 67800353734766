.gallery-title {
  font-family: 'Algerian', sans-serif;
  font-size: 4rem;
  color: #ffc107;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.gallery-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

/* Media query pour les iPhone 12 et autres petits écrans */
/* Media query pour les iPhone 12 et autres petits écrans */
@media (max-width: 390px) {
  .gallery-title {
    font-size: 3rem; /* Ajuster la taille de la police pour les petits écrans */
    top: 4rem; /* Ajuster la position du titre vers le bas */
  }

  .gallery-container {
    padding-top: 5rem; /* Ajuster le padding si nécessaire pour éviter le chevauchement */
  }
}

/* Styles uniques pour .image-item img sans répétition */
.image-item {
  width: 90vw; /* ou une autre largeur selon la conception */
  max-height: 40vh; /* ou une autre hauteur maximale selon la conception */
  margin-top: 30vh; /* Augmentez cette valeur pour abaisser l'image et les flèches */
  position: relative; /* Important pour le positionnement absolu des boutons de navigation */
  /* autres styles */
    text-align: center; /* Centre le contenu textuel, mais peut aussi aider avec les images inline */
    width: 100%; 
}

.image-item img {
  max-width: 90vw; /* Limite la largeur maximum de l'image à 90% de la largeur de la vue */
  max-height: 50vh; /* Limite la hauteur maximum de l'image à 50% de la hauteur de la vue */
  width: auto; /* S'assure que la largeur de l'image est automatiquement ajustée */
  height: auto; /* S'assure que la hauteur de l'image est automatiquement ajustée */
  object-fit: contain; /* Garantit que tout le contenu de l'image est visible et maintient les proportions */
  margin-top: -30vh; /* Ajoute un peu d'espace au-dessus de l'image, ajustez selon besoin */
  display: block; /* Assure que l'image est correctement affichée en bloc */
  justify-content: center;
}




.image-item img:hover {
  transform: scale(1.05); /* Légère augmentation de taille au survol */
}

/* Boutons de navigation ajustés pour l'image */
.navigation-buttons {
  display: flex;
  position: absolute;
  width: 100%; /* Étend les boutons sur toute la largeur du conteneur */
  bottom: 100px; /* Ajustez selon la proximité souhaitée par rapport au bord inférieur de l'image */
  justify-content: space-between; /* Sépare les boutons de chaque côté */
}

.navigation-button {
  background: none;
  border: none;
  color: #ffc107;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Styles pour la flèche suivante lorsque c'est la seule affichée */
.next-button.only-button {
  left: 50%; /* Centre la flèche suivante horizontalement si nécessaire */
  transform: translateX(-50%); /* Alignement précis */
  bottom: 10%; /* Position à mi-chemin entre l'image et le bas de l'écran */
}


@media (max-width: 768px) {
  .image-item {
    width: calc(50% - 10px); /* Deux images par ligne sur tablette */
  }
}

@media (max-width: 480px) {
  .image-item {
    width: 100%; /* Une image par ligne sur mobile */
  }
}
